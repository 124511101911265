import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, LinearProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../auth/useAuthContext';
import { NAV } from '../../config-global';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../logo';
import ProgressBar from '../progress-bar';
import { useSettingsContext } from '../settings';
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 9998,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));
// ----------------------------------------------------------------------
export default function LoadingScreen() {
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'lg');
    const { isInitialized } = useAuthContext();
    const { themeLayout } = useSettingsContext();
    const isDashboard = isInitialized && pathname.includes('/') && isDesktop;
    const size = (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) ||
        (themeLayout === 'vertical' && NAV.W_DASHBOARD) ||
        128;
    return (_jsxs(_Fragment, { children: [_jsx(ProgressBar, {}), _jsx(StyledRoot, { sx: {
                    ...(isDashboard && {
                        zIndex: 0,
                        width: `calc(100% - ${size}px)`,
                        ...(themeLayout === 'horizontal' && {
                            width: 1,
                            height: `calc(100% - ${size}px)`,
                        }),
                    }),
                }, children: isDashboard ? (_jsx(LinearProgress, { color: "inherit", sx: { width: 1, maxWidth: 360 } })) : (_jsxs(_Fragment, { children: [_jsx(m.div, { animate: {
                                scale: [1, 0.9, 0.9, 1, 1],
                                opacity: [1, 0.48, 0.48, 1, 1],
                            }, transition: {
                                duration: 2,
                                ease: 'easeInOut',
                                repeatDelay: 1,
                                repeat: Infinity,
                            }, children: _jsx(Logo, { disabledLink: true, sx: { width: 64, height: 64 } }) }), _jsx(Box, { component: m.div, animate: {
                                scale: [1.6, 1, 1, 1.6, 1.6],
                                rotate: [270, 0, 0, 270, 270],
                                opacity: [0.25, 1, 1, 1, 0.25],
                                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                            }, transition: { ease: 'linear', duration: 3.2, repeat: Infinity }, sx: {
                                width: 100,
                                height: 100,
                                position: 'absolute',
                                border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                            } }), _jsx(Box, { component: m.div, animate: {
                                scale: [1, 1.2, 1.2, 1, 1],
                                rotate: [0, 270, 270, 0, 0],
                                opacity: [1, 0.25, 0.25, 0.25, 1],
                                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                            }, transition: {
                                ease: 'linear',
                                duration: 3.2,
                                repeat: Infinity,
                            }, sx: {
                                width: 120,
                                height: 120,
                                position: 'absolute',
                                border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                            } })] })) })] }));
}
